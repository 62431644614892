












































































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import darkTheme from 'highcharts/themes/dark-unica';
import useAppConfig from '../@core/app-config/useAppConfig';
import moment from 'moment';
import axios from 'axios';
import highcharts from 'highcharts';

export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },

  data() {
    const range = [moment().subtract(1, 'days').valueOf(), moment().valueOf()];
    return {
      dataTypes: [
        { txt: '小時資料', id: 'hour' },
        { txt: '分鐘資料', id: 'min' },
      ],
      statusFilters: [
        { id: 'all', txt: '全部' },
        { id: 'normal', txt: '正常量測值' },
        { id: 'calbration', txt: '校正' },
        { id: 'maintance', txt: '維修' },
        { id: 'invalid', txt: '無效數據' },
        { id: 'valid', txt: '有效數據' },
      ],
      display: false,
      chartTypes: [
        {
          type: 'line',
          desc: '折線圖',
        },
        {
          type: 'spline',
          desc: '曲線圖',
        },
        {
          type: 'area',
          desc: '面積圖',
        },
        {
          type: 'areaspline',
          desc: '曲線面積圖',
        },
        {
          type: 'column',
          desc: '柱狀圖',
        },
        {
          type: 'scatter',
          desc: '點圖',
        },
      ],
      earthAir: [
        {
          type: 'O2CO2',
          desc: 'O2 vs CO2',
        },
        {
          type: 'CO2N2',
          desc: 'CO2 vs N2',
        },
        {
          type: 'CO2N2/O2',
          desc: 'CO2 vs N2/O2',
        },
      ],
      form: {
        monitors: Array<string>(),
        monitorTypes: Array<string>(),
        earthAir: 'O2CO2',
        dataType: 'hour',
        statusFilter: 'all',
        range,
      },
    };
  },
  computed: {
    ...mapState('monitorTypes', ['monitorTypes']),
    ...mapGetters('monitorTypes', ['activatedMonitorTypes', 'mtMap']),
    ...mapState('monitors', ['monitors']),
    myMonitors(): Array<any> {
      return this.monitors.filter((m: any) => m._id === 'me');
    },
  },
  watch: {},
  async mounted() {
    const { skin } = useAppConfig();
    if (skin.value == 'dark') {
      darkTheme(highcharts);
    }

    await this.fetchMonitorTypes();
    await this.fetchMonitors();

    if (this.activatedMonitorTypes.length !== 0)
      this.form.monitorTypes.push(this.activatedMonitorTypes[0]._id);

    if (this.monitors.length !== 0) {
      this.form.monitors.push(this.myMonitors[0]._id);
    }
  },
  methods: {
    ...mapActions('monitorTypes', ['fetchMonitorTypes']),
    ...mapActions('monitors', ['fetchMonitors']),
    ...mapMutations(['setLoading']),
    async query() {
      switch (this.form.earthAir) {
        case 'O2CO2':
          this.form.monitorTypes = ['O2', 'CO2'];
          break;
        case 'CO2N2':
          this.form.monitorTypes = ['CO2', 'N2'];
          break;
        case 'CO2N2/O2':
          this.form.monitorTypes = ['CO2', 'N2-O2'];
          break;
      }
      if (this.form.monitorTypes.length !== 2) {
        this.$bvModal.msgBoxOk('測項數必須為2個');
        return;
      }
      this.setLoading({ loading: true });
      try {
        this.display = true;
        const monitors = this.form.monitors.join(':');
        const url = `/ScatterChart/${monitors}/${this.form.monitorTypes.join(
          ':',
        )}/${this.form.dataType}/${this.form.statusFilter}/${
          this.form.range[0]
        }/${this.form.range[1]}`;
        const res = await axios.get(url);
        const ret = res.data;

        ret.legend = {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          x: 100,
          y: 70,
          floating: true,
          borderWidth: 1,
        };

        let mt1 = this.mtMap.get(this.form.monitorTypes[0]);
        let mt2 = this.mtMap.get(this.form.monitorTypes[1]);
        ret.plotOptions = {
          scatter: {
            marker: {
              radius: 3,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)',
                },
              },
            },
            states: {
              hover: {
                marker: {
                  enabled: false,
                },
              },
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: `${mt1.desp}{point.x} ${mt1.unit}, ${mt2.desp}{point.y} ${mt2.unit}`,
            },
          },
        };

        ret.colors = [
          '#7CB5EC',
          '#434348',
          '#90ED7D',
          '#F7A35C',
          '#8085E9',
          '#F15C80',
          '#E4D354',
          '#2B908F',
          '#FB9FA8',
          '#91E8E1',
          '#7CB5EC',
          '#80C535',
          '#969696',
        ];

        let prec = Math.max(mt1.prec, mt2.prec);

        ret.tooltip = { valueDecimals: prec };
        //ret.legend = { enabled: true };
        ret.credits = {
          enabled: false,
          href: 'http://www.wecc.com.tw/',
        };

        highcharts.chart('chart_container', ret);
      } catch (err) {
        throw Error(`${err}`);
      } finally {
        this.setLoading({ loading: false });
      }
    },
    setThisWeek() {
      const startOfTheWeek = moment().startOf('week').valueOf();
      this.form.range = [startOfTheWeek, moment().valueOf()];
    },
    setThisMonth() {
      const startOfThisMonth = moment().startOf('month').valueOf();
      this.form.range = [startOfThisMonth, moment().valueOf()];
    },
    setThisQ() {
      const startOfThisQ = moment().startOf('Q').valueOf();
      this.form.range = [startOfThisQ, moment().valueOf()];
    },
  },
});
